'use client';

import LoginForm from '@/components/LoginForm';
import NextImage from 'next/image';
import { Image } from '@nextui-org/react';
import { useRouter } from 'next/navigation';
import { useRef, useState } from 'react';
import { RenderImageContext, RenderImageProps, RowsPhotoAlbum } from 'react-photo-album';
import 'react-photo-album/rows.css';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import { MotionValue, motion, useScroll, useSpring, useTransform } from 'framer-motion';
import { useTranslations } from 'next-intl';

import TitleLogo from '@/public/static/TitleLogo.svg';
import Pushka from '@/public/static/pushka.webp';

import child_1 from '@/public/static/children/child_1.webp';
import child_2 from '@/public/static/children/child_2.webp';
import child_3 from '@/public/static/children/child_3.webp';
import child_4 from '@/public/static/children/child_4.webp';
import child_5 from '@/public/static/children/child_5.webp';
import child_6 from '@/public/static/children/child_6.webp';
import child_7 from '@/public/static/children/child_7.webp';
import child_8 from '@/public/static/children/child_8.webp';

import gallery_1 from '@/public/static/gallery/gallery_1.webp';
import gallery_2 from '@/public/static/gallery/gallery_2.webp';
import gallery_3 from '@/public/static/gallery/gallery_3.webp';
import gallery_4 from '@/public/static/gallery/gallery_4.webp';
import gallery_5 from '@/public/static/gallery/gallery_5.webp';
import gallery_6 from '@/public/static/gallery/gallery_6.webp';

const photos = [
  {
    src: gallery_1.src,
    width: 800,
    height: 800,
  },
  {
    src: gallery_2.src,
    height: 534,
    width: 800,
  },
  {
    src: gallery_4.src,
    height: 640,
    width: 960,
  },
  {
    src: gallery_6.src,
    height: 1368,
    width: 2700,
  },
  {
    height: 880,
    src: gallery_5.src,
    width: 800,
  },
  {
    height: 522,
    src: gallery_3.src,
    width: 800,
  },
];

// const features = [
//   {
//     title: `Simple Setup`,
//     description: `Register, receive a QR code for each child, and embark on a journey of compassion without the hassle of omplex logins.`,
//   },
//   {
//     title: `Donate with Purpose`,
//     description: `Begin with a generous allotment of 30 virtual coins for every child. And for every dollar you graciously donate to MADA, your child receives an additional 4 coins to champion their chosen cause.`,
//   },
//   {
//     title: `Engage, Connect, & Grow`,
//     description: `Dive into stories that resonate with the heart. Choose causes that matter, and witness the transformation in your child as they recognize the profound value of their contributions. With The Chesed Club, children experience the power of positive change, while parents see the foundation for compassionate leaders of tomorrow being set.`,
//   },
// ];

const childrenImages = [
  {
    start: -9,
    end: 35,
    image: child_1,
    className: 'rounded-t-[100px]',
    rotator: new MotionValue(),
  },
  {
    start: -44,
    end: -29,
    image: child_2,
    className: 'rounded-tl-[100px] rounded-br-[100px]',
    rotator: new MotionValue(),
  },
  {
    start: 44,
    end: -26,
    image: child_3,
    className: 'rounded-tr-[100px]',
    rotator: new MotionValue(),
  },
  {
    start: -41,
    end: 49,
    image: child_4,
    className: 'rounded-full',
    rotator: new MotionValue(),
  },
  {
    start: 41,
    end: 58,
    image: child_5,
    className: 'rounded-tr-[100px] rounded-bl-[100px]',
    rotator: new MotionValue(),
  },
  {
    start: -61,
    end: 38,
    image: child_6,
    className: 'rounded-full',
    rotator: new MotionValue(),
  },
  {
    start: 17,
    end: -20,
    image: child_7,
    className: 'rounded-b-[100px]',
    rotator: new MotionValue(),
  },
  {
    start: -9,
    end: 46,
    image: child_8,
    className: 'rounded-tl-[100px]',
    rotator: new MotionValue(),
  },
];

type HomePageProps = {
  params: {
    locale: string;
  };
};

export default function HomePage({ params }: HomePageProps) {
  const t = useTranslations('HomePage');
  const [index, setIndex] = useState(-1);
  const router = useRouter();
  const childrenImagesRef = useRef(null);
  const { scrollYProgress } = useScroll({ target: childrenImagesRef, offset: ['start end', 'end start'] });
  const springedProgress = useSpring(scrollYProgress, { bounce: 0, stiffness: 100, damping: 30 });

  for (const child of childrenImages) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    child.rotator = useTransform(springedProgress, [0, 1], [child.start, child.end]);
  }

  const featureKeys = ['feature-1', 'feature-2', 'feature-3'] as const;

  function renderNextImage({ alt = '', title, sizes }: RenderImageProps, { photo, width, height }: RenderImageContext) {
    return (
      <div
        style={{
          width: '100%',
          position: 'relative',
          aspectRatio: `${width} / ${height}`,
        }}
        className="mx-1 first-of-type:ml-0 first-of-type:mr-1 last-of-type:ml-1 last-of-type:mr-0 border-3 border-black"
      >
        <NextImage
          fill
          src={photo}
          alt={alt}
          title={title}
          sizes={sizes}
          placeholder={'blurDataURL' in photo ? 'blur' : undefined}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="p-8 flex flex-col md:flex-row-reverse gap-6 place-self-center place-content-center">
        <div className="flex flex-1 place-content-center">
          <NextImage src={Pushka} alt="Pushka" width={460} height={460} priority={true} />
        </div>
        <div className="flex flex-col gap-1 flex-1 max-w-lg">
          <NextImage
            src={TitleLogo}
            alt="The Chesed Club"
            width={456}
            height={456}
            className="place-self-center object-scale-down"
          />
          <span className="font-titillium-web uppercase font-bold  italic text-center text-lg">{t('slogan')}</span>

          <p className="font-karla text-center text-lg leading-6">
            {t('about-1')}
            <br />
            <br />
            {t('about-2')}
            <br />
          </p>
          <div className="items-center ">
            <LoginForm isCTA={true} onSuccess={(email) => router.push('/login/?email=' + email)} />
          </div>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto px-4  md:px-8">
        <ul className="grid gap-x-12 border-black divide-y [&>.feature-1]:pl-0 sm:grid-cols-2 sm:gap-y-8 sm:divide-y-0 lg:divide-x lg:grid-cols-3 lg:gap-x-0">
          {featureKeys.map((feature, idx) => (
            <li
              key={idx}
              className={`feature-${idx + 1} border-black inline-flex flex-col space-y-2 py-8 lg:px-12 sm:py-0`}
            >
              <strong className="text-2xl font-semibold text-center">{t(`${feature}.title`)}</strong>
              <p className="text-lg font-light text-center">{t(`${feature}.description`)}</p>
            </li>
          ))}
        </ul>
      </div>

      <div
        ref={childrenImagesRef}
        className="grid grid-cols-2 lg:grid-cols-4 m-8 place-items-center place-content-center"
      >
        {childrenImages.map((child, index) => (
          <motion.div key={index} className="flex" style={{ rotate: child.rotator }}>
            <Image
              as={NextImage}
              src={child.image.src}
              alt={'Child ' + (index + 1)}
              width={250}
              height={340}
              className={'my-10 border-3 border-black ' + child.className}
            />
          </motion.div>
        ))}
      </div>

      <a href={`/causes`} data-w-id="9e10ebc0-b1e9-ebfd-6308-aa7cf5fd1d9b" className="button discover inline-block">
        <h6 className="button-text">{t('discover-causes')}</h6>
        <div className="button-hover"></div>
      </a>

      <div className="p-4 w-full">
        <RowsPhotoAlbum
          photos={photos}
          render={{ image: renderNextImage }}
          defaultContainerWidth={1200}
          sizes={{
            size: '1168px',
            sizes: [{ viewport: '(max-width: 1200px)', size: 'calc(100vw - 32px)' }],
          }}
          spacing={8}
          onClick={({ index }) => setIndex(index)}
        />
        <Lightbox
          slides={photos}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          // enable optional lightbox plugins
          plugins={[Zoom]}
        />
      </div>
    </div>
  );
}
